import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { MainColour } from '../../utils/variables';

import loadComponent from '../Loadable';

const H2 = loadComponent('h2');

export default function CheckoutSummary({ items, currency }) {
  return (
    <div style={{ marginBottom: `30px` }}>
      <H2>Payment Summary</H2>
      <div style={summaryItems}>
        {items.map((item, i) => {
          const cost = item.price * item.quantity;
          return (
            <div
              key={item.id}
              style={{
                display: `flex`,
                marginTop: i !== 0 && `0.9375rem`,
              }}
            >
              <div style={{ flex: `0 0 50px` }}>
                <strong style={{ color: MainColour }}>{item.quantity}x</strong>
              </div>
              <div style={infoStyle}>
                <strong style={{ fontSize: `1.125rem` }}>
                  {item.name} Processing
                </strong>
                <div style={detailStyle}>
                  <p style={{ margin: 0 }}>
                    {getSymbolFromCurrency(currency)}
                    {parseFloat(Math.round(cost * 100) / 100).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const summaryItems = {
  display: `flex`,
  flexDirection: `column`,
};

const infoStyle = {
  flex: 1,
};

const detailStyle = {
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
};
